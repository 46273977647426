/*============ site logo ============*/
a.logo {
    position: fixed;
    font-family: $font;
    font-size:14px;
    z-index: 1030;
    top: 50px;
    left: 100px;
    display: inline-block;
    width: 200px;
    height:auto;
    transition: all .5s ease;
}
 /* Mobile menu icon styled in _menu.scss */

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 960px) {
    a.logo {
        top: 15px;
        left: 25px;
        width: 25vw;
    }
}