@import '_colours.scss';
@import '_fonts.scss';
@import '_constants.scss';
html {
    color: #222;
    font-size: 1em;
    line-height: 1.4;
}
::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
}
::selection {
    background: #b3d4fc;
    text-shadow: none;
}
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}
fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}
textarea {
    resize: vertical;
}
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}
/* ==========================================================================
   Author's custom styles
   ========================================================================== */
body {
    padding:0;
    margin:0;
    font-size:14px;
    background-color: #FFF;
}
.contentwrap {
    max-width:1000px;
    width:100%;
    margin: 0 auto;
    position:relative;
    padding: 20px;
    z-index: 50;
    box-sizing: border-box;
}
.contentwrap.narrow {
    max-width: 550px;
}
.page-content {
    position: relative;
    z-index: 100;
}
img {
    border:none;
}
header a,
footer a {
    text-decoration: none;
    color: inherit;
}
.flex {
    display: flex;
}
.wrap {
    flex-wrap: wrap;
}
.flex.column {
    flex-direction: column;
}
.align-start {
    align-items: flex-start;
}
.align-center {
    align-items: center;
}
.justify-start {
    justify-content: flex-start;
}
.justify-end {
    justify-content: flex-end;
}
.justify-center {
    justify-content: center;
}
.justify-evenly {
    justify-content: space-evenly;
}
.justify-between {
    justify-content: space-between;
}
/*============ Arrow ============*/
.arrow {
    background-image: url('../assets/arrow.svg');
    height:55px;
    width: 55px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.arrow.horizontal {
    width: 50px;
    height: 50px;
}
.prev,
.next {
    position: absolute;
    cursor: pointer;
    top: 50%;
    z-index: 100;
}
.prev.arrow.horizontal {
    transform: rotate(90deg);
    left:6vw;
}
.prev.arrow.horizontal::after {
    content: "Back";
    position: absolute;
    transform: rotate(-90deg);
    bottom: 100px;
    font-size: 20px;
    text-transform: uppercase;

}
.next.arrow.horizontal {
    transform: rotate(-90deg);
    left: calc(100vw - 9vw);
}
/*============ Splash ============*/
#home .logo {
    background-image: url('../assets/logo.svg');
    height: 300px;
    width: 300px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.inner-content {
    z-index: 20;
    height: 80vh;
}
.inner-content .highlight.white {
    color: #FFF;
    text-align: center;
}
/*home page overlay */
#home .page-content::before {
    content: '';
    position: absolute;
    background-color: $transparent-blue;
    z-index: 20;
    height:  $white-border-height;
    width:  $white-border-width;
    border-radius: $white-border-radius;
}
#home .page-content {
    align-items: flex-end;
}
#home .arrow {
    animation: shakevert 6s;
    animation-iteration-count: infinite;
}
@keyframes shakevert {
	0% { transform: translate(0, 5px); }
	10% { transform: translate(0, -1px); }
	20% { transform: translate(0, 5px); }
	30% { transform: translate(0, -1px); }
	40% { transform: translate(0, 5px); }
	50% { transform: translate(0, -1px); }
	60% { transform: translate(0, 5px); }
	70% { transform: translate(0, -1px); }
	80% { transform: translate(0, 5px); }
	90% { transform: translate(0, -1px); }
	100% { transform: translate(0, 5px); }
}
.mfp-zoom-out-cur {
    overflow: hidden;
}
.lazyload {
    background-image: url('../assets/fallback.jpg') !important;
}
/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 1260px) {
    .contentwrap {
        max-width: 80vw;
    }
}
@media only screen and (max-width: 960px) {
    .prev.arrow.horizontal::after {
        content:'';
    }
    .contentwrap,
    .contentwrap.narrow {
        max-width: 100vw;
    }
    .contentwrap.narrow.align-start {
       align-items: center;
    }
}
@media only screen and (max-width: 760px) {
    .prev.arrow.horizontal {
        left:2vw;
    }
    .next.arrow.horizontal {
        left: calc(100vw - 60px);
    }
    a {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;
        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
}
@media only screen and (max-width: 360px) {
    #home .logo {
        height: 90vw;
        width: 90vw;
    }
}
/* ==========================================================================
   Helper classes
   ========================================================================== */
.hidden {
    display: none !important;
    visibility: hidden;
}
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}
.invisible {
    visibility: hidden;
}
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}
.clearfix:after {
    clear: both;
}
.clearfix {
    *zoom: 1;
}
/* ==========================================================================
   Print styles
   ========================================================================== */
@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }
    a,
    a:visited {
        text-decoration: underline;
    }
    a[href]:after {
        content: " (" attr(href) ")";
    }
    abbr[title]:after {
        content: " (" attr(title) ")";
    }
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    img {
        max-width: 100% !important;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
}
@import '_header.scss';
@import '_menu.scss';
@import '_page.scss';
@import '_gallery.scss';
@import '_contact.scss';