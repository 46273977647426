/*============ page============*/
section,
.page-container,
.page-content {
    position: relative;
    height: 100vh;
    max-height:100vh;
    width: 100vw;
}
/* smaller bg image w/ white border */
.white-border .page-content {
    height: $white-border-height;
    width: $white-border-width;
    border-radius: $white-border-radius;
}
/* bg img position */
.bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.split .bg-img {
    position: relative;
    height: $white-border-height;
    width: $split-width;
    border-radius: $white-border-radius;
}
/* alternating page overlay */
.page.blue-overlay .full.bg-img::before,
.page.white-border:not(#home) .full.bg-img::before,
.page.white-border:not(#home) .full.bg-img::after {
    content: '';
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}
.page.white-border:not(#home) .full.bg-img::before,
.page.white-border:not(#home) .full.bg-img::after,
.page.blue-overlay .bg-img:not(.full)::before {
    border-radius: $white-border-radius;
}
/* orange overlay for all pages with white border */
.page.white-border:not(#home) .full.bg-img::before {
    background-color: $transparent-orange;
    z-index: 30;
}
/* darker navy overlay for all full bg images except for first page in slider */
.page.blue-overlay .full.bg-img::before {
    background-color: $transparent-navy;
    z-index: 20;
}
/* blue overlay for first full page in slider and all pages with white border */
.page.blue-overlay .page-container:first-of-type .full.bg-img::before,
.page.white-border:not(#home) .page-container .full.bg-img::after {
    background-color: $transparent-blue;
}
.slider .page-container:nth-of-type(n+1) .page-content {
    justify-content: flex-end;
}
.slider {
    position: relative;
    transition: all 0.8s ease-in-out;
}
.page-body {
    max-width: 300px;
    width: 100%;
    max-height: 60vh;
    overflow-y: auto;
}
.page-heading,
.page-body.index {
    max-width: 300px;
    width: 100%;
}
.page-body.index h2 {
    margin-bottom:0;
}
.index a {
    text-decoration: none;
    color: inherit;
}
.page.next,
.page.back {
    width: 10vw;
}
.split .contentwrap {
    width: $split-width;
    margin: 0;
    color: $blue;
}
.full .contentwrap {
    margin:0;
}
.blue-overlay .split {
    width: $white-border-width;
}
#about-us .slider .page-container:first-of-type .contentwrap {
    margin: 0 auto;
    flex-direction: row;
}
/* Events */
#events .page-heading h1 {
    color: $aqua;
}
#events .page-body.justify-end {
    justify-content: flex-start;
    max-height: 40vh;
}
#events .page-summary {
    margin-bottom: 0;
}
.event-date {
    font-size:19px;
    font-weight: 600;
    margin-top:5px;
}
[data-edit] {
    padding-right: 15px;
}
[data-edit] ul {
    padding-left:15px;
    line-height: 1.8;
}
.page-body::-webkit-scrollbar {
    width: 3px;
}
section#gallery::-webkit-scrollbar {
    height: 15px;
}
.page-body::-webkit-scrollbar-track,
section#gallery::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #d2d2d2;
    border-radius: 10px;
}
.page-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 12px $aqua;
}
section#gallery::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 60px $aqua;
}
/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 960px) {
    #about-us .slider .page-container:first-of-type .contentwrap {
        flex-direction: column;
    }
    .split .bg-img {
        position: absolute;
        height: 92vh;
        width: 95vw;
    }
    .split .contentwrap {
        width: 100vw;
        color: #FFF;
    }
    .page-body,
    .page-heading,
    .page-body.index {
        max-width: 60vw;
    }
    /* apply alternating page overlay to split pages as well as full*/
    .page.blue-overlay .bg-img::before,
    .page.white-border:not(#home) .bg-img::before,
    .page.white-border:not(#home) .bg-img::after {
        content: '';
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
    }
    .page.white-border:not(#home) .bg-img::before,
    .page.white-border:not(#home) .bg-img::after,
    .page.blue-overlay .bg-img:not(.full)::before {
        border-radius: $white-border-radius;
    }
    /* orange overlay for all pages with white border */
    .page.white-border:not(#home) .bg-img::before {
        background-color: $transparent-orange;
        z-index: 30;
    }
    /* darker navy overlay for all bg images except for first page in slider */
    .page.blue-overlay .bg-img::before {
        background-color: $transparent-navy;
        z-index: 20;
    }
    /* blue overlay for first page in slider and all pages with white border */
    .page.blue-overlay .page-container:first-of-type .bg-img::before,
    .page.white-border:not(#home) .page-container .bg-img::after {
        background-color: $transparent-blue;
    }
    .slider {
        transition: all 0.4s ease-in-out;
    }
}

@media only screen and (max-width: 760px) {


    a {

        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        /* This is the dangerous one in WebKit, as it breaks things wherever */
        word-break: break-all;
        /* Instead use this non-standard one: */
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;

    }
}