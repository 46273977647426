/** form generic **/
#contact.flex {
    flex-direction: row;
    overflow-y: hidden;
}
#contact .arrow.next.horizontal,
#contact .arrow.prev.horizontal {
    display: none;
}
.aqua-bg {
    background-color: $aqua;
    flex-grow:1;
    height: 100%;
    padding: 20px 5vw;
    font-size: 16px;
    max-width: 25vw;
}
.item.phone {
    margin:0;
}
.item:hover,
.item:hover a,
item a:hover,
.site-link:hover {
    color: $blue;
    transition: all 0.5s ease;
    font-weight: 500;
}
.tel,
.email {
    text-decoration: none;
    color: #FFF;
}
#form-result {
    height: 100%;
    flex-grow:2;
    padding: 20px 10vw;
}

h2.form-result {
    font-size: 35px;
    padding-top: 0vh;
}
#form-result h2.uppercase {
    margin-top:0;
}
h3.form-result {
    font-size: 20px;
    padding-bottom: 0vh;
}
form {
    width: 100%;
    box-sizing: border-box;
}
form p {
    position: relative;
    margin: 0;
}
form .aqua-border {
    border: 1px solid $aqua;
    border-radius: 10px;
    margin: 10px 0;
    text-align: left;
}
label.float,
input {
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
    padding-left: 20px;
}
input,
textarea {
    text-align: left;
    padding-left: 20px;
    font-family: $font;
    font-size: 16px;
    padding-top: 15px;
    width: 100%;
    box-sizing: border-box;
    border:none;
    color: #FFF;
}
input,
textarea,
.button.aqua-border {
    background-color: $blue;
}
textarea {
    min-height: 80px;
    border-radius: 10px;
}
label.float {
    position: absolute;
    left:0;
    color: inherit;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 0.3s ease;
    z-index: 1;
    top:0;
}
.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    left: 5px;
    padding-left: 0;
    /*line-height: 1;*/
    font-size: 0.75em;
    top:-10px;
}
p.js-float-wrap.flex {
    flex-grow:1;
}
form button {
    width: 100%;
    cursor: pointer;
}
form .button.aqua-border:hover {
    background-color: $aqua;
    color: $blue;
}
form .button.aqua-border:active {
    background-color: #FFF;
}
/*============ Contact ============*/
section.contact {
    background-color: $blue;
}
form {
    padding-top: 20px;
}
/** site links **/
.site-link {
    display: inline-block;
    text-transform: uppercase;
    margin: 0px;
    text-decoration: none;
    color: #FFF;
}
.site-link.text::after {
    content:'/';
    margin: 0 5px;
}
.site-link.text:last-of-type::after {
    content:'';
}

/*============ footer ============*/
footer {
    position: relative;
    width: 100vw;
    bottom: 50px;
}
footer .contentwrap {
    padding: 0 20px;
}
/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: grey;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}
/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 1024px) and (pointer: coarse), (max-width: 1024px) and (hover: none) {
    footer {
        position: absolute;
        bottom: 10px;

    }
}
@media only screen and (max-width: 960px) {
    #contact.flex {
        justify-content: flex-start;
        left:0;
        min-width: 100vw;
        width:180vw;
        transition: all .5s ease-in-out;
    }
    #contact .arrow.next.horizontal {
        display: block;
    }
    #contact .arrow.next.horizontal,
    #contact .arrow.prev.horizontal {
        left: calc(100vw - 60px);
    }
    .aqua-bg {
        flex-grow: unset;
        min-width: 80vw;
        align-items: center;
        box-sizing: border-box;
        padding-left: 10vw;
    }
    #form-result {
        min-width: 100vw;
        padding-left:15vw;
        padding-right: 15vw;
    }
    form .aqua-border {
        border: none;
        flex-direction: column;
        margin:0;
    }
    p.js-float-wrap,
    button.button {
        border: 1px solid $aqua;
        border-radius: 10px;
    }
    p.js-float-wrap {
        margin-bottom: 10px;
    }
}