section#gallery {
    overflow-x: auto;
}
.kmsgallery {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

}
.kmsgallery-thumbnail-image {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex:1;
    justify-content: flex-start; /* align items in Main Axis */
    align-items: stretch; /* align items in Cross Axis */
    align-content: stretch; /* Extra space in Cross Axis */
    z-index: 10;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin:0;
    width: unset;
    height: unset;
    min-width: 430px;
    min-height: 300px;
}
.kmsgallery-thumbnail-image::before {
    content: '';
    position: absolute;
    background-color: $transparent-blue;
    z-index: 10;
    top:0;
    left:0;
    right:0;
    bottom:0;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 760px) {
    .kmsgallery-thumbnail-image {
        min-width: 50vw;
        min-height:50vw;
    }
}