/** menu generic **/
nav ul {
    list-style:none;
    padding: 0;
    margin: 0;
}
nav li {
    display: inline-block;
    box-sizing: border-box;
}
nav a {
    display: block;
    color:inherit;
    text-decoration: none;
}
#mobile-menu {
    position: fixed;
    display: block;
    font-size: 16px;
    top: 120px;
    right: 80px;
    margin: 2px;
    margin-bottom: 0;
    cursor: pointer;
    z-index: 1030;
    width: fit-content;
    text-transform: lowercase;
    font-style: italic;
}
/***** Slide menu ****/
.slide-menu {
    display:block;
}
.slide-menu {
	background: $aqua;
    width:100vw;
    z-index:1000;
	position: fixed;
	height: 100%;
	top: 0;
	right: -100vw;
	overflow-y:scroll;
    font-family: $heading-font;
}
.slide-menu-active .slide-menu {
    right: 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    overflow: auto;
}
.site-content-menu-slide {
    /*overflow-x:hidden;*/
    position:relative;
    right:0;
    height:100vh;
}
/* transition for menu slide */
.slide-menu,
.site-content-menu-slide,
#mobile-menu {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}
.slide-menu ul {
    padding:0;
    margin:0;
    list-style:none;
}
.slide-menu > ul {
    padding-right: 30vw;
}
.slide-menu li {
    position:relative;
    display: block;
    line-height: 1.3;
}
.slide-menu li.top > a {
    font-style: italic;
    padding-top: 1vw;
    padding-bottom: 0.2vw;
}
.slide-menu a {
	display: block;
	color: #fff;
	font-size: 18px;
	font-weight: 300;
	padding: 0px;
	text-decoration:none;
}
.slide-menu li.has-sub {
    position: relative;
    display: block;
}
.slide-menu li.depth-1 {
    text-transform: uppercase;
    font-weight: bold;
}
.slide-menu li.depth-1 a {
    font-weight: 600;
}
.slide-menu a:hover {
	color: $blue;
}
.slide-menu-active #mobile-menu {
    right: 80px;
}
.slide-menu-active #mobile-menu .menu.aqua {
    color: #FFF !important;
}
/* ==========================================================================
   Media Queries
   ========================================================================== */
@media only screen and (max-width: 1024px) and (pointer: coarse), (max-width: 1024px) and (hover: none) {
    .site-content-menu-slide {
        height: auto;
    }
}
@media only screen and (max-width: 960px) {
    #mobile-menu {
        top: 30px;
        right: 30px;
    }
    .slide-menu-active #mobile-menu {
        right: 30px;
    }
}
